
class Callbacks {
  static recordToFormHandler() {
    return function recordToForm(record) {
      return {
        name: _.get(record, 'name'),
        display_index: _.get(record, 'display_index'),
        authorized_phone_number: _.get(record, 'authorized_phone_number'),
        schedule_delay: _.get(record, 'schedule_delay'),
        label_for_call_center: _.get(record, 'label_for_call_center'),
        label_for_driver: _.get(record, 'label_for_driver'),
        ivr_access: _.get(record, 'ivr_access'),
        dispatch_priority: _.get(record, 'dispatch_priority'),
        fixed_fee: _.get(record, 'fixed_fee'),
        fee_rate: _.get(record, 'fee_rate'),
        to_check_billing: _.get(record, 'to_check_billing'),
        deferred_invoicing_or_inapp_invoice: _.get(record, 'deferred_invoicing_or_inapp_invoice'),
        is_given_priority_for_incident_processing: _.get(record, 'is_given_priority_for_incident_processing'),
        is_authorized: _.get(record, 'is_authorized'),
        can_call_booker: record?.can_call_booker,
        can_call_driver: record?.can_call_driver
      }
    }
  }

  static getEmptyFormHandler() {
    return function getEmptyFormValues() {
      return {
        display_index: 0,
        can_call_booker: true,
        can_call_driver: true
      }
    }
  }

  static recordToStateHandler() {
    return function recordToState(record) {
      return {
        title: _.get(record, 'name')
      }
    }
  }

  static formToRecordHandler() {
    return function formToRecord(values, extra, meta, state) {
      return {
        name: _.get(values, 'name'),
        display_index: _.get(values, 'display_index'),
        authorized_phone_number: _.get(values, 'authorized_phone_number'),
        schedule_delay: _.get(values, 'schedule_delay'),
        label_for_call_center: _.get(values, 'label_for_call_center'),
        label_for_driver: _.get(values, 'label_for_driver'),
        ivr_access: _.get(values, 'ivr_access'),
        dispatch_priority: _.get(values, 'dispatch_priority'),
        fixed_fee: _.get(values, 'fixed_fee'),
        fee_rate: _.get(values, 'fee_rate'),
        to_check_billing: _.get(values, 'to_check_billing'),
        deferred_invoicing_or_inapp_invoice: _.get(values, 'deferred_invoicing_or_inapp_invoice'),
        is_given_priority_for_incident_processing: _.get(values, 'is_given_priority_for_incident_processing'),
        is_authorized: _.get(values, 'is_authorized'),
        can_call_booker: values?.can_call_booker,
        can_call_driver: values?.can_call_driver
      }
    }
  }
}

export default Callbacks
