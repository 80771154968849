import React, { useEffect, useState, useCallback } from 'react'
import { hooks } from '@front/volcanion'

const withContainer = Component => props => {
  const [selectedRow, setSelectedRow] = hooks.useGlobalState('selected_validation_order')
  const [, setOpenSearchForm] = hooks.useGlobalState('open_search_form')
  const [openList, setOpenList] = hooks.useGlobalState('open_search_list')
  const [billingAction] = hooks.useModelFunction('order', 'billingAction')

  const orders_ids = hooks.useSearchListenerList('order', 'order_validation_list')
  const { isReady } = hooks.useSearchListenerStatus('order', 'order_validation_list')
  const first_order = _.head(orders_ids)

  const handleBillingAction = useCallback(async (action, record_id) => {
    const current_order = _.findIndex(orders_ids, elem => elem === record_id)

    try {
      await billingAction({ action, order_id: record_id })

      if (current_order + 1 > orders_ids.length)
        setSelectedRow(orders_ids[0])
      else setSelectedRow(orders_ids[current_order + 1])

    } catch (err) {
      console.log('Validation : ', err)
    }
  }, [orders_ids, setSelectedRow])

  useEffect(() => {
    if (!!isReady && !selectedRow && !!first_order) {
      setSelectedRow(first_order)
      setOpenSearchForm(false)
    }
  }, [isReady, first_order])

  useEffect(() => {
    setOpenSearchForm(true)
  }, [])

  const mergedProps = {
    handleBillingAction,
    setOpenList,
    openList,
    setSelectedRow,
    selectedRow
  }

  return (
    <Component {...mergedProps} />
  )
}

export default withContainer
