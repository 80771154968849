import Aux from './Aux'
class Callbacks {
  static filterToFormHandler(company_id) {
    return function filterToForm(filter) {
      return {
        billing_status: ['to_check', 'to_validate'],
        ...filter
      }
    }
  }

  static formToFilterHandler(setOpenList) {
    return function formToFilter(values, extra, meta, state) {
      const { requestedAt_start, requestedAt_end, ...rest } = values
      const loadsType = _.get(rest, 'commercial_package.load_type')
      setOpenList(true)
      return {
        requestedAt: Aux.formatRequestedAt(requestedAt_start, requestedAt_end),
        ...!loadsType ? _.omit(rest, ['commercial_package.load_type']) : rest,
      }
    }
  }
}

export default Callbacks
